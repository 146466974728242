<template>
  <div>
    <b-card class="blog-edit-wrapper" :title="tituloForm">
      <validation-observer ref="simpleRules">
        <b-form>
          <b-row>
            <!-- titulo -->
            <b-col md="6">
              <b-form-group label-for="Titulo">
                <label >Título <span class="text-danger">(*)</span></label>
                <validation-provider
                  #default="{ errors }"
                  name="Titulo"
                  rules="required"
                >
                  <b-form-input
                    id="titulo"
                    v-model="titulo"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Titulo"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-for="error in errores.titulo"
                    :key="error"
                    class="text-danger"
                    >{{ error }}</small
                  >
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label-for="Autor">
                <label >Autor <span class="text-danger">(*)</span></label>
                <validation-provider
                  #default="{ errors }"
                  name="Autor"
                  rules="required"
                >
                  <b-form-input
                    v-model="autor"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Autor"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-for="error in errores.autor"
                    :key="error"
                    class="text-danger"
                    >{{ error }}</small
                  >
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <b-form-group label-for="Archivo">
                <label >Archivo <span class="text-danger">(*)</span></label>
                <validation-provider
                  #default="{ errors }"
                  name="Archivo"
                  :rules="id ? '' : 'required'"
                >
                  <b-input-group>
                    <b-form-file
                      @change="onFileChanged"
                      accept=".pdf"
                      v-model="archivo"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Seleccione el libro ..."
                      drop-placeholder="Soltar archivo aqui ..."
                    />
                    <b-input-group-append>
                      <b-button
                        variant="danger"
                        :disabled="archivo == null"
                        @click="archivo = null"
                      >
                        <feather-icon icon="XIcon" />
                      </b-button>
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-for="error in errores.archivo"
                    :key="error"
                    class="text-danger"
                    >{{ error }}</small
                  >
                </validation-provider>
              </b-form-group>
              <b-card-text class="my-1" v-if="id && archivo == null">
                <feather-icon icon="FileIcon" size="24" />
                Archivo seleccionado: <strong>{{ nombreArchivo }}</strong>
              </b-card-text>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="4" sm="6">
              <b-form-group label-for="Categoria">
                <label >Categoria <span class="text-danger">(*)</span></label>
                <validation-provider
                  #default="{ errors }"
                  name="Categoria"
                  rules="required"
                >
                  <b-row>
                    <b-col cols="9" style="padding-right: 0">
                      <v-select
                        id="blog-edit-categoria"
                        v-model="categoria_id"
                        label="title"
                        :state="errors.length > 0 ? false : null"
                        :options="categoriaOpciones"
                        :clearable="false"
                      >
                        <template #list-header>
                          <li
                            v-b-modal.modal-categoria-libro
                            @click="accionCategoria = 'registrar'"
                            class="
                              add-new-client-header
                              d-flex
                              align-items-center
                              my-50
                            "
                          >
                            <feather-icon icon="PlusIcon" size="16" />
                            <span class="align-middle ml-25"
                              >Nueva Categoria</span
                            >
                          </li>
                        </template>
                      </v-select>
                    </b-col>
                    <b-col cols="3" style="padding-left: 0">
                      <b-button
                        v-b-modal.modal-categoria-libro
                        @click="abriComponenteCategoria(categoria_id)"
                        variant="outline-warning"
                      >
                        <feather-icon icon="Edit2Icon" />
                      </b-button>
                    </b-col>
                  </b-row>
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-for="error in errores.categoria_id"
                    :key="error"
                    class="text-danger"
                    >{{ error }}</small
                  >
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="4" sm="6">
              <b-form-group label-for="Tipo">
                <label >Tipo <span class="text-danger">(*)</span></label>
                <validation-provider
                  #default="{ errors }"
                  name="Tipo"
                  rules="required"
                >
                  <b-row>
                    <b-col cols="9" style="padding-right: 0">
                      <v-select
                        id="blog-edit-tipo"
                        v-model="tipo_id"
                        label="title"
                        :state="errors.length > 0 ? false : null"
                        :options="tipoOpciones"
                        :clearable="false"
                      >
                        <template #list-header>
                          <li
                            v-b-modal.modal-tipo-libro
                            @click="accionTipo = 'registrar'"
                            class="add-new-client-header d-flex align-items-center my-50"
                          >
                            <feather-icon icon="PlusIcon" size="16" />
                            <span class="align-middle ml-25">Nuevo Tipo</span>
                          </li>
                        </template>
                      </v-select>
                    </b-col>
                    <b-col cols="3" style="padding-left: 0">
                      <b-button
                        v-b-modal.modal-tipo-libro
                        @click="abriComponenteTipo(tipo_id)"
                        variant="outline-warning"
                      >
                        <feather-icon icon="Edit2Icon" />
                      </b-button>
                    </b-col>
                  </b-row>

                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-for="error in errores.tipo_id"
                    :key="error"
                    class="text-danger"
                    >{{ error }}</small
                  >
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="4" sm="6">
              <b-form-group label="Edicion" label-for="Edicion">
                <validation-provider
                  #default="{ errors }"
                  name="Edicion"
                  rules="integer|digits:4"
                >
                  <b-form-input
                    id="anio"
                    v-model="anio"
                    type="number"
                    placeholder="Edicion"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6" sm="6">
              <b-form-group label="Editorial" label-for="Editorial">
                <b-form-input
                  id="editorial"
                  v-model="editorial"
                  placeholder="Editorial"
                />
              </b-form-group>
            </b-col>
            <b-col md="6" sm="6">
              <b-form-group label="Fecha Publicacion" label-for="Editorial">
                <flat-pickr
                v-model="published_at"
                class="form-control"
                :config="{ enableTime: false,dateFormat: 'Y-m-d'}"
              />
              <small v-for="error in errores.published_at" :key="error" class="text-danger">{{ error }}</small>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6" class="mb-2">
              <div class="border rounded p-2">
                <h5 class="text-center"><b>Foto Portada</b></h5>
                <div class="text-center">
                  <b-form-group label="" label-for="">
                    <div class="box-imagen box-imagen1 border rounded">
                      <b-img
                        v-if="miniatura"
                        :src="miniatura"
                        center
                        fluid
                        thumbnail
                        class="rounded"
                        alt="Card image cap"
                      />
                    </div>
                  </b-form-group>
                  <b-button
                    v-b-modal.modal-seleccionar-imagen
                    variant="flat-dark"
                    size="sm"
                  >
                    <feather-icon icon="ImageIcon" class="mr-50" />
                    <span class="align-middle">Elegir Imagen</span>
                  </b-button>
                </div>
              </div>
            </b-col>
            <b-col md="6">
              <b-row>
                <b-col cols="12">
                  <!-- Descripcion -->
                  <b-form-group label="Descripcion" label-for="descripcion">
                    <quill-editor
                      style="
                        min-height: 160px;
                        border: 1px solid #ccc;
                        border-top-right-radius: 0.357rem;
                        border-top-left-radius: 0.357rem;
                      "
                      id="quil-content"
                      v-model="descripcion"
                      :options="editorOption"
                      class="border-bottom-0"
                    />
                    <div
                      id="quill-toolbar"
                      class="d-flex justify-content-end border-top-0"
                    >
                      <!-- Add a bold button -->
                      <button class="ql-bold" />
                      <button class="ql-italic" />
                      <button class="ql-underline" />
                      <button class="ql-link" />
                    </div>
                    <!--<b-form-textarea
                    id="descripcion"
                    placeholder="Descripcion"
                    rows="8"
                    v-model="descripcion"
                  />-->
                    <small
                      v-for="error in errores.descripcion"
                      :key="error"
                      class="text-danger"
                      >{{ error }}</small
                    >
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <!-- Etiquetas -->
                  <b-form-group label="Etiquetas" label-for="Etiquetas">
                    <v-select
                      id="blog-edit-etiquetas"
                      v-model="etiquetas_id"
                      multiple
                      :close-on-select="false"
                      label="title"
                      :options="etiquetasOpciones"
                    >
                      <template #list-header>
                        <li
                          v-b-modal.modal-etiqueta
                          @click="accionEtiqueta = 'registrar'"
                          class="
                            add-new-client-header
                            d-flex
                            align-items-center
                            my-50
                          "
                        >
                          <feather-icon icon="PlusIcon" size="16" />
                          <span class="align-middle ml-25">Nueva Etiqueta</span>
                        </li>
                      </template>
                    </v-select>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <!-- submit -->
          <b-row>
            <b-col cols="12" class="mt-50">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-1"
                @click="validar(), (guardarSalir = true)"
              >
                Guardar
              </b-button>

              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                variant="outline-secondary"
                :to="{ name: 'biblioteca' }"
              >
                Cerrar
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
      <!--Componente Categoria -->
      <CategoriaNuevoEditar
        :accion="accionCategoria"
        :item="itemCategoria"
        @cerrarComponente="cerrarComponenteCategoria"
        @actualizarLista="actualizarListaCategoria"
      >
      </CategoriaNuevoEditar>
      <TipoLibroNuevoEditar
        :accion="accionTipo"
        :item="itemTipo"
        @cerrarComponente="cerrarComponenteTipo"
        @actualizarLista="actualizarListaTipo"
      >
      </TipoLibroNuevoEditar>
      <!--Componente Etiquetas -->
      <EtiquetaNuevoEditar
        :accion="accionEtiqueta"
        :item="itemEtiqueta"
        @cerrarComponente="cerrarComponenteEtiqueta"
        @actualizarLista="actualizarListaEtiqueta"
      >
      </EtiquetaNuevoEditar>
      <!--Componente Imagen -->
      <ImagenComponent @enviarImagen="recibirImagen"></ImagenComponent>
    </b-card>
  </div>
</template>
<script>
import axiosIns from "@/libs/axios";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  BCard,
  BCardText,
  BButton,
  BRow,
  BCol,
  BCardBody,
  BCardTitle,
  BCardSubTitle,
  BLink,
  BFormGroup,
  BFormInput,
  BForm,
  BFormTextarea,
  BImg,
  BFormFile,
  BFormDatepicker,
  BDropdown,
  BDropdownItem,
  BInputGroup,
  BInputGroupPrepend,
  BInputGroupAppend,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { required } from "@validations";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";

import CategoriaNuevoEditar from "@/views/aplicaciones/configuracion/categoriaLibro/CategoriaNuevoEditar.vue";
import TipoLibroNuevoEditar from "@/views/aplicaciones/configuracion/tipoLibro/TipoLibroNuevoEditar.vue";
import EtiquetaNuevoEditar from "@/views/aplicaciones/configuracion/etiqueta/EtiquetaNuevoEditar.vue";
import ImagenComponent from "@/componentes/ImagenComponent.vue";
import { quillEditor } from "vue-quill-editor";
const Spanish = require('flatpickr/dist/l10n/es');
export default {
  components: {
    BCard,
    BCardText,
    BButton,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BForm,
    ValidationProvider,
    ValidationObserver,
    BFormTextarea,
    vSelect,
    BImg,
    BFormFile,
    BFormDatepicker,
    flatPickr,
    BDropdown,
    BDropdownItem,
    BInputGroup,
    BInputGroupPrepend,
    BInputGroupAppend,

    CategoriaNuevoEditar,
    EtiquetaNuevoEditar,
    TipoLibroNuevoEditar,
    ImagenComponent,
    quillEditor,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      //datos de entrada
      published_at:null,
      id: null,
      titulo: "",
      autor: "",
      descripcion: "",
      contenido: "",
      anio: "",
      archivo: null,
      peso: "",
      editorial: "",
      miniatura: "",
      tipo_id: "",
      tipoOpciones: [],
      categoria_id: "",
      categoriaOpciones: [],
      etiquetas_id: [],
      etiquetasOpciones: [],
      //fin datos de entrada
      required,
      submitted: false,
      guardarSalir: true,
      errores: [],
      editorOpcion: {
        placeholder: "Ingrese el contenido aqui ...",
      },
      //editor: ClassicEditor,
      editorConfig: {},
      accionCategoria: "",
      itemCategoria: [],
      accionEtiqueta: "",
      itemEtiqueta: [],
      nombreArchivo: "",
      accionTipo: "",
      itemTipo: "",
      editorOption: {
        modules: {
          toolbar: "#quill-toolbar",
        },
        placeholder: "Descripcion ...",
      },
      //editorCk:[]
    };
  },

  computed: {
    tituloForm() {
      return this.id ? "Editar Libro" : "Nuevo Libro";
    },
  },
  watch: {
    $route: function (val) {
      if (val.params.id) {
        this.editarEntrada(this.$route.params.id);
      } else {
        this.limpiar();
      }
    },
  },
  created() {
    this.listarEtiquetas();
    this.listarCategorias();
    this.listarTipoLibros();
    this.date();
  },
  methods: {
    onFileChanged(event) {
      if (event.target.files[0]) {
        let file = event.target.files[0];
        if (file == null) {
          this.archivo = null;
        } else {
          this.peso = (file.size / 1024).toFixed(2);
        }
      } else {
        this.archivo = null;
        this.peso = "";
      }
    },
    validar() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          if (!this.submitted) {
            this.submitted = true;
            if (this.id) {
              this.actualizar();
            } else {
              this.registrar();
            }
          }
        }
      });
    },
        date(){
      const hoy = new Date();
      var date = hoy.getFullYear()+'-'+(hoy.getMonth()+1)+'-'+hoy.getDate();
      //var time = hoy.getHours()+':'+hoy.getMinutes();
      this.published_at = date ;
      //+ ' '+ time;
    },
    listarEtiquetas() {
      var url = "/admin/etiquetas";
      axiosIns
        .get(url)
        .then((res) => {
          this.etiquetasOpciones = res.data.map((g) => ({
            title: g.nombre,
            value: g.id,
          }));
        })
        .catch((err) => {
          console.log(err);
        });
    },
    listarCategorias() {
      var url = "/admin/categoria-libros";
      axiosIns
        .get(url)
        .then((res) => {
          this.categoriaOpciones = res.data.map((g) => ({
            title: g.nombre,
            value: g.id,
          }));
        })
        .catch((err) => {
          console.log(err);
        });
    },
    listarTipoLibros() {
      var url = "/admin/tipo-libros";
      axiosIns
        .get(url)
        .then((res) => {
          this.tipoOpciones = res.data.map((g) => ({
            title: g.nombre,
            value: g.id,
          }));
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //categoria
    actualizarListaCategoria(item) {
      this.listarCategorias();
      this.categoria_id = { title: item.nombre, value: item.id };
      this.accionCategoria = "";
      this.itemCategoria = [];
    },
    abriComponenteCategoria(item) {
      this.accionCategoria = "actualizar";
      this.itemCategoria = item;
    },
    cerrarComponenteCategoria() {
      this.accionCategoria = "";
      this.itemCategoria = [];
    },

    //tipo libro
    actualizarListaTipo(item) {
      console.log(item);
      this.listarTipoLibros();
      this.tipo_id = { title: item.nombre, value: item.id };
      this.accionTipo = "";
      this.itemTipo = [];
    },
    abriComponenteTipo(item) {
      this.accionTipo = "actualizar";
      this.itemTipo = item;
    },
    cerrarComponenteTipo() {
      this.accionTipo = "";
      this.accionTipo = [];
    },

    //etiqueta
    actualizarListaEtiqueta(item) {
      this.listarEtiquetas();
      this.etiquetas_id.push({ title: item.nombre, value: item.id });
      this.accionEtiqueta = "";
      this.itemEtiqueta = [];
    },
    abriComponenteEtiqueta(item) {
      this.accionEtiqueta = "actualizar";
      //this.itemEtiqueta=item;
    },
    cerrarComponenteEtiqueta() {
      this.accionEtiqueta = "";
      this.itemEtiqueta = [];
    },
    //RECIBIR IMAGEN DEL COMPONENTE
    recibirImagen(imagen) {
      this.miniatura = imagen.url;
    },

    //REGISTRO
    async registrar() {
      let formData = new FormData();
      formData.append("titulo", this.titulo);
      formData.append("autor", this.autor);
      formData.append("contenido", this.contenido);
      formData.append("categoria_id", this.categoria_id.value);
      formData.append("descripcion", this.descripcion);
      formData.append("archivo", this.archivo);
      formData.append("miniatura", this.miniatura);
      formData.append("tipo_id", this.tipo_id.value);
      formData.append("peso", this.peso);
      formData.append("editorial", this.editorial);
      formData.append("anio", this.anio);
      formData.append("published_at",this.published_at,);
      formData.append("etiquetas_id", JSON.stringify(this.etiquetas_id));
      axiosIns
        .post("/admin/libros", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          this.$bvToast.toast("Exito", {
            title: "Libro de guardo con exito",
            variant: "success",
            solid: false,
          });
          if (this.guardarSalir) {
            this.$router.push({ name: "biblioteca" });
          } else {
            this.id = res.data;
            this.$router.push({
              name: "biblioteca-editar",
              params: { id: res.data },
            });
            this.editarEntrada(this.id);
          }
          this.errores = [];
          this.submitted = false;
        })
        .catch((err) => {
          this.submitted = false;
          this.errores = err.response.data.errors;
        });
    },
    async actualizar() {
      let formData = new FormData();
      formData.append("titulo", this.titulo);
      formData.append("autor", this.autor);
      formData.append("contenido", this.contenido);
      formData.append("categoria_id", this.categoria_id.value);
      formData.append("descripcion", this.descripcion);
      formData.append("archivo", this.archivo);
      formData.append("miniatura", this.miniatura);
      formData.append("tipo_id", this.tipo_id.value);
      formData.append("peso", this.peso);
      formData.append("editorial", this.editorial);
      formData.append("anio", this.anio);
      formData.append("published_at",this.published_at,);
      formData.append("etiquetas_id", JSON.stringify(this.etiquetas_id));
      axiosIns
        .post("/admin/libros/" + this.id, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          this.$bvToast.toast("Exito", {
            title: "Libro de guardo con exito",
            variant: "success",
            solid: false,
          });
          if (this.guardarSalir) {
            this.$router.push({ name: "biblioteca" });
          } else {
            this.editarEntrada(this.id);
          }
          this.errores = [];
          this.submitted = false;
        })
        .catch((err) => {
          this.submitted = false;
          this.errores = err.response.data.errors;
        });
    },
    editarEntrada(id) {
      this.id = id;
      axiosIns
        .get("/admin/libros/" + this.id)
        .then((res) => {
          this.archivo = null;
          this.titulo = res.data.titulo;
          this.published_at=res.data.published_at;
          this.contenido = res.data.contenido;
          this.categoria_id = {
            title: res.data.categoria.nombre,
            value: res.data.categoria.id,
          };
          this.miniatura = res.data.miniatura;
          this.descripcion = res.data.descripcion;
          this.etiquetas_id = res.data.etiquetas.map((g) => ({
            title: g.nombre,
            value: g.id,
          }));
          this.tipo_id = {
            title: res.data.tipo.nombre,
            value: res.data.tipo.id,
          };
          this.autor = res.data.autor;
          if (res.data.anio == null) {
            this.anio = "";
          } else {
            this.anio = res.data.anio;
          }
          if (res.data.editorial == null) {
            this.editorial = "";
          } else {
            this.editorial = res.data.editorial;
          }
          this.nombreArchivo = res.data.archivo;
        })
        .catch((err) => {});
    },
    limpiar() {
      this.date();
      this.id = null;
      this.titulo = "";
      this.contenido = "";
      this.categoria_id = "";
      this.miniatura = "";
      this.etiquetas_id = [];
      this.descripcion = "";
      this.autor = "";
      this.tipo_id = "";
      this.editorial = "";
      this.anio = "";
      this.archivo = null;
      this.nombreArchivo = "";
    },
  },
  mounted() {
    if (this.$route.params.id) {
      this.editarEntrada(this.$route.params.id);
    }
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/quill.scss";
.box-imagen {
  height: 13rem;
}
.box-imagen img {
  width: 100%;
  height: 100%;
}
.box-imagen1 img {
  object-fit: contain;
}
.add-new-client-header {
  padding: $options-padding-y $options-padding-x;
  color: $success;

  &:hover {
    background-color: rgba($success, 0.12);
  }
}
.vs__open-indicator {
  fill: rgba(60, 60, 60, 0.5);
}
.ql-container.ql-snow {
  border: 0;
}
</style>
<style lang="scss" scoped>
@import "~@core/scss/base/bootstrap-extended/include";

.assignee-selector {
  ::v-deep .vs__dropdown-toggle {
    padding-left: 0;
  }
}
#quil-content ::v-deep {
  > .ql-container {
    border-bottom: 0;
  }

  + #quill-toolbar {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
  }
}
</style>